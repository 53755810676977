export const profiles = [
  {
    id: 1,
    name: "Ben",
    age: "26",
    submittedBy: "Rachel and Jo",
    pictureLink: "/ben.png",
  },
  {
    id: 2,
    name: "Nathan",
    age: "28",
    submittedBy: "Rachel and Jo",
    pictureLink: "/nathan.png",
  },
  {
    id: 3,
    name: "Sawyer",
    age: "28",
    submittedBy: "Paige",
    pictureLink: "/sawyer.png",
  },
];
